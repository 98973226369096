import './meter.css';

export default function Meter({ data: { name = '', roomUsage = 0, bill, data = [] } = {} }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    };
    return date.toLocaleString('en-IN', options);
  };

  const isToday = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const lastSyncDateTime = data.length ? formatDate(data[0].lastSync) : '';

  return (
    <div className="card w-100 mb-0">
      <h5 className="card-title m-0 p-2 card-heading">{name}</h5>
      <div className="inner-card monthly-card" style={{ backgroundColor: roomUsage > 7000 ? '#dc3545' : '#20c997' }}>
        <h5 className="card-title">Monthly</h5>
        <h5 className="card-text">{roomUsage} L</h5>
      </div>
      <table className="card-table table mb-0">
        <tbody>
          <tr className="table-heading">
            <td colSpan="2">Monthly Usage</td>
          </tr>
          {
            data.map(({ meterId, meterType, totalUsage, lastSync }) => (
              <tr key={meterId}>
                <td>{meterType}</td>
                <td><strong>{`${totalUsage} L`}</strong></td>
                <td>{isToday(lastSync) && <span className="latest-sync">New</span>}</td>
              </tr>
            ))
          }
          <tr>
            <td><strong>Bill</strong></td>
            <td colSpan="3"><strong style={{ color: roomUsage > 7000 ? 'red' : '' }}>{bill}</strong></td>
          </tr>
        </tbody>
      </table>
      <div className="p-2" style={{ background: '#f1f1f1' }}><strong>Last Sync: </strong>{lastSyncDateTime}</div>
    </div>
  );
}
